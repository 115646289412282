<template>
  <div class="app-container">

    <!-- 流程设计器，负责绘制流程等 -->
    <my-process-designer class="hbbpmn" :key="`designer-${reloadIndex}`" v-model="xmlString" v-bind="controlForm" keyboard
      ref="processDesigner" @init-finished="initModeler" @save="save" />

    <!-- 流程属性器，负责编辑每个流程节点的属性 -->
    <my-properties-panel :key="`penal-${reloadIndex}`" :bpmn-modeler="modeler" :prefix="controlForm.prefix"
      class="process-panel" :model="model" style="height: 386px; overflow-y: auto;" />

  </div>
</template>

<script>
// 自定义元素选中时的弹出菜单（修改 默认任务 为 用户任务）
import CustomContentPadProvider from "@/components/bpmnProcessDesigner/package/designer/plugins/content-pad";
// 自定义左侧菜单（修改 默认任务 为 用户任务）
import CustomPaletteProvider from "@/components/bpmnProcessDesigner/package/designer/plugins/palette";
import MyProcessPalette from "@/components/bpmnProcessDesigner/package/palette/ProcessPalette";
import axios from 'axios'

export default {
  name: "App",
  components: { MyProcessPalette },
  data() {
    return {
      moduleId: '',
      token:'',
      ajaxUrl: '//ccecloud.ergateapp.com/api/blank/',
      // ajaxUrl: '',
      xmlString: "", // BPMN XML
      modeler: null,
      reloadIndex: 0,
      controlDrawerVisible: false,
      controlForm: {
        simulation: true,
        labelEditing: false,
        labelVisible: false,
        prefix: "activiti",
        headerButtonSize: "mini",
        additionalModel: [CustomContentPadProvider, CustomPaletteProvider]
      },
      addis: {
        CustomContentPadProvider,
        CustomPaletteProvider
      },
      // 流程模型的信息
      model: {},
    };
  },
  created() {

    const { modelId, projectId,token,version,tenantId } = this.$route.query;
    console.log(modelId)
    this.moduleId = modelId
    this.token=token
    axios.defaults.headers['Authorization'] = token;
    axios.defaults.headers['VERSION'] = version;
    axios.defaults.headers['TENANT-ID'] = tenantId;
    axios.defaults.headers['PROJECT-ID'] = projectId;

    this.init()
  },
  methods: {
    xmlcl(data) {
      console.log(data)
      this.xmlString = data.bpmnXml
      this.model = {
        ...data,
        bpmnXml: undefined, // 清空 bpmnXml 属性
      }
    },
    init() {
      let that = this
      axios({
        method: 'get',
        url: that.ajaxUrl + 'bpm/model/get?id=' + that.moduleId,
      }).then(function (response) {
        console.log(response)
        if (response.data.code == 0) {
          if (response.data.data) {
            let data = response.data.data
            that.xmlcl(data)
          }
        }
      }).catch(function (error) {
        // handle error
        console.log(error);
        // that.$message.error(error.msg)
      })
    },
    initModeler(modeler) {
      setTimeout(() => {
        this.modeler = modeler;
        console.log(modeler);
      }, 10);
    },
    reloadProcessDesigner(deep) {
      this.controlForm.additionalModel = [];
      for (let key in this.addis) {
        if (this.addis[key]) {
          this.controlForm.additionalModel.push(this.addis[key]);
        }
      }
      deep && (this.xmlString = undefined);
      this.reloadIndex += 1;
      this.modeler = null; // 避免 panel 异常
      // if (deep) {
      //   this.xmlString = undefined;
      //   this.$refs.processDesigner.processRestart();
      // }
    },
    save(bpmnXml) {
      let that = this
      const data = {
        ...this.model,
        bpmnXml: bpmnXml, // this.bpmnXml 只是初始化流程图，后续修改无法通过它获得
      }
      console.log(data)
      // 修改的提交

      axios({
        method: 'post',
        url: that.ajaxUrl + 'bpm/model/saveModel',
        data: {
          "modelId": that.moduleId,
          "name": data.name,
          "description": data.name,
          "bpmnXml": data.bpmnXml
        }
      }).then(function (response) {
        console.log(response)
        if (response.data.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success'
          });
        }else{
          that.$message(response.data.msg);
        }
      }).catch(function (error) {
        // handle error
        console.log(error);
        if (error.response.data.msg) {
          that.$message.error(error.response.data.msg)
        } else {

          that.$message.error('保存失败！')
        }
      })
    },
    /** 关闭按钮 */
    close() {
      this.$tab.closeOpenPage({ path: "/bpm/manager/model" });
    },
  }
};
</script>
<style lang="scss">
body {
  overflow: hidden;
  margin: 0;
  box-sizing: border-box;
}

.hbbpmn {
  width: 100%;
  height: 100%;
}

.my-process-designer__container,
.my-process-designer__canvas {
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: inline-grid;
  grid-template-columns: 100px auto max-content;
}

.bts-icon {
  display: none;
}

.demo-control-bar {
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 1;

  .open-control-dialog {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 32px;
    background: rgba(64, 158, 255, 1);
    color: #ffffff;
    cursor: pointer;
  }
}

// TODO 芋艿：去掉多余的 faq
//.info-tip {
//  position: fixed;
//  top: 40px;
//  right: 500px;
//  z-index: 10;
//  color: #999999;
//}

.control-form {
  .el-radio {
    width: 100%;
    line-height: 32px;
  }
}

.element-overlays {
  box-sizing: border-box;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: #fafafa;
}

.my-process-designer {
  height: calc(100vh - 84px);
}

.process-panel__container {
  position: absolute;
  right: 0;
  top: 55px;
  height: calc(100vh - 84px);
}
</style>
